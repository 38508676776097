
<div class="border-border"> </div>
      <section class="page-section" id="about">
        <div class="container">
          <div class="text-center">


    </div>
    <ul class="timeline">
      <li>
        <div class="timeline-image"><img class="rounded-circle img-fluid" src="./assets/gründung.png" alt="" /></div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h4>November 2012</h4>
            <h4 class="subheading">Gründung Native Coffee</h4>
          </div>
          <div class="timeline-body"><p class="text-muted">Am 12. November gründeten Marvin Frei und Ueli Maurer das Unternehmen Native Coffee.</p></div>
        </div>
      </li>
      <li class="timeline-inverted">
        <div class="timeline-image"><img class="rounded-circle img-fluid" src="./assets/4Africa.png" alt="" /></div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h4>März 2013</h4>
            <h4 class="subheading">Start der Kooperation mit 4Africa</h4>
          </div>
          <div class="timeline-body"><p class="text-muted">Am 24. März setzten sich Ueli Maurer und 4Africa zusammen um eine Idee zu kreieren, die Bauern in Äthiopien zu unterstützen. </p></div>
        </div>
      </li>
      <li>
        <div class="timeline-image"><img class="rounded-circle img-fluid" src="./Bauer.png" alt="" /></div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h4>Juli 2013</h4>
            <h4 class="subheading">Erster Vertrag mit Bauer</h4>
          </div>
          <div class="timeline-body"><p class="text-muted">Anfangs Juli starten wir die ersten Kooperationen mit klein Bauern aus Äthiopien.</p></div>
        </div>
      </li>
      <li class="timeline-inverted">
        <div class="timeline-image"><img class="rounded-circle img-fluid" src="./shops.png" alt="" /></div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h4>July 2014</h4>
            <h4 class="subheading">Mehrere Filialien</h4>
          </div>
          <div class="timeline-body"><p class="text-muted">Weitere Filialien in Zürich und Genf. Seit 2014 bis heute sind wir um 13 Standorte gewachsen.</p></div>
        </div>
      </li>
      <li class="timeline-inverted">
        <div class="timeline-image">
          <h4>
            Be Part
            <br />
            Of Our
            <br />
            Story!
          </h4>
        </div>
      </li>
    </ul>
  </div>
</section>
