<header class="masthead">
  <div class="container h-100">
    <div class="row h-100 align-items-center justify-content-center text-center">
      <div class="col-lg-10 align-self-end">
        <h1 class="text-uppercase text-white font-weight-bold">Trinken Sie Kaffee mit einem guten Gewissen</h1>
        <hr class="divider my-4 colorchange" />
      </div>
      <div class="col-lg-8 align-self-baseline">
        <p class="text-white-75 font-weight-light mb-5">Native Coffee ist eine Reihe von Kaffees, welche in der ganzen Schweiz vertretten ist. Native Coffee setzt dabei stark auf ihren Ökologischen Fussabdruck und versucht es unseren Kunden weiter zu leiten.</p>
      </div>
    </div>
  </div>
</header>
