
<header class="masthead">
  <div class="container h-100">
    <div class="row h-100 align-items-center justify-content-center text-center">
      <div class="col-lg-10 align-self-end">
        <h1 class="text-uppercase text-white font-weight-bold">Bern Bundesplatz</h1>
        <hr class="divider my-4 colorchange" />
      </div>
      <div class="col-lg-8 align-self-baseline">
        <div class="row text-center justify-content-center pt-5 top">
          <div class="col-12 col-md-7">
            <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Standort wählen
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" routerLink="/standorte/wil">Wil</a>
                <a class="dropdown-item" routerLink="/standorte/bernparadeplatz">Bern Paradeplatz</a>
                <a class="dropdown-item" routerLink="/standorte/bernbundesplatz">Bern Bundesplatz</a>
                <a class="dropdown-item" routerLink="/standorte/stgallenhsg">St.Gallen HSG</a>
                <a class="dropdown-item" routerLink="/standorte/wil">St.Gallen UBS Platz</a>
                <a class="dropdown-item" routerLink="/standorte/wil">Dietlikon</a>
                <a class="dropdown-item" routerLink="/standorte/wil">Genf Paradeplatz</a>
                <a class="dropdown-item" routerLink="/standorte/wil">Genf Hochfachschule</a>
                <a class="dropdown-item" routerLink="/standorte/wil">Genf Park</a>
                <a class="dropdown-item" routerLink="/standorte/wil">Chur</a>
                <a class="dropdown-item" routerLink="/standorte/wil">St.Moritz</a>
                <a class="dropdown-item" routerLink="/standorte/wil">Arosa</a>
                <a class="dropdown-item" routerLink="/standorte/wil">Andermatt</a>
                <a class="dropdown-item" routerLink="/standorte/wil">Frauenfeld</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<section class="pb-5 pt-0 margin-bottom">
  <div class="container-fluid p-0 pb-3">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21579.061048444553!2d9.035965436220526!3d47.46297268101151!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aebc2a107cf7b%3A0x23cebff4e805181b!2s9500%20Wil!5e0!3m2!1sde!2sch!4v1592397226187!5m2!1sde!2sch" width="100%" height="300" frameborder="0" style="border:0" allowfullscreen=""></iframe>
  </div>


  <link href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
  <script src="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/js/bootstrap.min.js"></script>
  <script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>


  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">

  <section class="our-webcoderskull padding-lg">
    <div class="container ">
      <ul class="row">
        <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style="height: 349px;">
            <figure><img src="http://www.webcoderskull.com/img/team4.png" class="img-responsive" alt=""></figure>
            <h3><a>Marvin Frei</a></h3>
            <p>Geschäftsführer</p>
            <ul class="follow-us clearfix">
              <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
            </ul>
          </div>
        </li>
        <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style="height: 349px;">
            <figure><img src="http://www.webcoderskull.com/img/team1.png" class="img-responsive" alt=""></figure>
            <h3><a>Ueli Maurer</a></h3>
            <p>Mitarbeiter</p>
            <ul class="follow-us clearfix">
              <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
            </ul>
          </div>
        </li>
        <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style="height: 349px;">
            <figure><img src="http://www.webcoderskull.com/img/team4.png" class="img-responsive" alt=""></figure>
            <h3><a>Manish Sepp </a></h3>
            <p>Mitarbeiter</p>
            <ul class="follow-us clearfix">
              <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
            </ul>
          </div>
        </li>
        <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style="height: 349px;">
            <figure><img src="http://www.webcoderskull.com/img/team2.png" class="img-responsive" alt=""></figure>
            <h3><a>Marcel Koch</a></h3>
            <p>Mitarbeiter</p>
            <ul class="follow-us clearfix">
              <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </section>
</section>

