import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {KontaktComponent} from './kontakt/kontakt.component';
import {HomeComponent} from './home/home.component';
import {StandorteComponent} from './standorte/standorte.component';
import {BernParadeplatzComponent} from './standorte/bern-paradeplatz/bern-paradeplatz.component';
import {BernBundesplatzComponent} from './standorte/bern-bundesplatz/bern-bundesplatz.component';
import {StgallebhsgComponent} from './standorte/stgallebhsg/stgallebhsg.component';
import {WirComponent} from './wir/wir.component';
import {IntenseComponent} from './products/intense/intense.component';

const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'kontakt', component: KontaktComponent},
  { path: 'home', component: HomeComponent},
  { path: 'standorte/wil', component: StandorteComponent},
  { path: 'standorte/bernparadeplatz', component: BernParadeplatzComponent},
  { path: 'standorte/bernbundesplatz', component: BernBundesplatzComponent},
  { path: 'standorte/stgallenhsg', component: StgallebhsgComponent},
  { path: 'geschichte', component: WirComponent},
  { path: 'produkte', component: IntenseComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
