<div class="border-products"></div>
<div class="row text-center text-white mb-5">
  <div class="col-lg-7 mx-auto">
  </div>
</div>
<!-- End -->

<h2 class="text-center mt-0">Unser Angebot</h2>
<hr class="divider my-4 colorchange"/>
<div class="row margin-margin">
  <div class="col-lg-8 mx-auto">

    <!-- List group-->
    <ul class="list-group shadow">

      <!-- list group item-->
      <li class="list-group-item">
        <!-- Custom content-->
        <div class="media align-items-lg-center flex-column flex-lg-row p-3">
          <div class="media-body order-2 order-lg-1">
            <h5 class="mt-0 font-weight-bold mb-2">Chai Latte</h5>
            <p class="text-muted mb-0">Informationen über das Produkt, wo es hergestellt wurde und wie. Natürlich würde es mehr Produkte als bei diesem Beispiel geben. Und nicht mit den selben Bildern.</p>

            <div class="d-flex align-items-center justify-content-between mt-1">
              <h6 class="font-weight-bold my-2">8.00 CHF</h6>
              <ul class="list-inline small">
                <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                <li class="list-inline-item m-0"><i class="fa fa-star-o text-gray"></i></li>
              </ul>
            </div>
          </div><img src="./assets/tassimo_chai_latte_kapseln_tasse_640x640_1.png" alt="Generic placeholder image" width="200" class="ml-lg-5 order-1 order-lg-2">
        </div>
        <!-- End -->
      </li>
      <!-- End -->

      <!-- list group item-->
      <li class="list-group-item">
        <!-- Custom content-->
        <div class="media align-items-lg-center flex-column flex-lg-row p-3">
          <div class="media-body order-2 order-lg-1">
            <h5 class="mt-0 font-weight-bold mb-2">Sandwich</h5>
            <p class="text-muted mb-0">Informationen über das Produkt, wo es hergestellt wurde und wie. Natürlich würde es mehr Produkte als bei diesem Beispiel geben. Und nicht mit den selben Bildern.</p>

            <div class="d-flex align-items-center justify-content-between mt-1">
              <h6 class="font-weight-bold my-2">8.00 CHF</h6>
              <ul class="list-inline small">
                <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
              </ul>
            </div>
          </div><img src="./assets/tassimo_chai_latte_kapseln_tasse_640x640_1.png" alt="Generic placeholder image" width="200" class="ml-lg-5 order-1 order-lg-2">
        </div>
        <!-- End -->
      </li>
      <!-- End -->

      <!-- list group item -->
      <li class="list-group-item">
        <!-- Custom content-->
        <div class="media align-items-lg-center flex-column flex-lg-row p-3">
          <div class="media-body order-2 order-lg-1">
            <h5 class="mt-0 font-weight-bold mb-2">Java Chip</h5>
            <p class="text-muted mb-0">Informationen über das Produkt, wo es hergestellt wurde und wie. Natürlich würde es mehr Produkte als bei diesem Beispiel geben. Und nicht mit den selben Bildern.</p>

            <div class="d-flex align-items-center justify-content-between mt-1">
              <h6 class="font-weight-bold my-2">8.00 CHF</h6>
              <ul class="list-inline small">
                <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                <li class="list-inline-item m-0"><i class="fa fa-star-o text-gray"></i></li>
              </ul>
            </div>
          </div><img src="./assets/tassimo_chai_latte_kapseln_tasse_640x640_1.png" alt="Generic placeholder image" width="200" class="ml-lg-5 order-1 order-lg-2">
        </div>
        <!-- End -->
      </li>
      <!-- End -->

      <!-- list group item -->
      <li class="list-group-item">
        <!-- Custom content-->
        <div class="media align-items-lg-center flex-column flex-lg-row p-3">
          <div class="media-body order-2 order-lg-1">
            <h5 class="mt-0 font-weight-bold mb-2">Donut</h5>
            <p class="text-muted mb-0">Informationen über das Produkt, wo es hergestellt wurde und wie. Natürlich würde es mehr Produkte als bei diesem Beispiel geben. Und nicht mit den selben Bildern.</p>

            <div class="d-flex align-items-center justify-content-between mt-1">
              <h6 class="font-weight-bold my-2">8.00 CHF</h6>
              <ul class="list-inline small">
                <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
              </ul>
            </div>
          </div><img src="./assets/tassimo_chai_latte_kapseln_tasse_640x640_1.png" alt="Generic placeholder image" width="200" class="ml-lg-5 order-1 order-lg-2">
        </div>
        <!-- End -->
      </li>
      <!-- End -->

    </ul>
    <!-- End -->
  </div>
</div>

