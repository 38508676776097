import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { SliderComponent } from './slider/slider.component';
import { ProductsComponent } from './products/products.component';
import { NewsComponent } from './news/news.component';
import { FooterComponent } from './footer/footer.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { HomeComponent } from './home/home.component';
import { WerteComponent } from './werte/werte.component';
import { StandorteComponent } from './standorte/standorte.component';
import { BernParadeplatzComponent } from './standorte/bern-paradeplatz/bern-paradeplatz.component';
import { BernBundesplatzComponent } from './standorte/bern-bundesplatz/bern-bundesplatz.component';
import { StgallebhsgComponent } from './standorte/stgallebhsg/stgallebhsg.component';
import { WirComponent } from './wir/wir.component';
import { IntenseComponent } from './products/intense/intense.component';


@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    SliderComponent,
    ProductsComponent,
    NewsComponent,
    FooterComponent,
    KontaktComponent,
    HomeComponent,
    WerteComponent,
    StandorteComponent,
    BernParadeplatzComponent,
    BernBundesplatzComponent,
    StgallebhsgComponent,
    WirComponent,
    IntenseComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
