<div class="container">
<h2 class="text-center mt-0">News</h2>
<hr class="divider my-4 colorchange"/>
<section class="section">
<div class="top">
  <div class="container">
    <div class="row masonry-container">
      <div class="col-lg-4 col-sm-6 mb-5">
        <article class="text-center">
          <img class="img-fluid mb-4" src="./assets/close-up-of-coffee-cup-on-table-312418.jpg" alt="post-thumb">

          <hr class="divider my-4 colorchange" />
          <h4><a class="text-dark" href="blog-single.html">Der neue Chai Late</a></h4>
          <p class="text-muted mb-0">Chai Latte ist im Moment das absolute Trendgetränk. Aber was ist Chai Latte eigentlich ? Das Wort “chai” bedeutet soviel wie “Tee” und das Wort “ Latte” stammt aus dem italienischen und bedeutet “Milch”. Knapp zusammengefasst ist der Chai Latte "nur" ein Tee mit Milch.</p>
        </article>
      </div>
      <div class="col-lg-4 col-sm-6 mb-5">
        <article class="text-center">
          <img class="img-fluid mb-4" src="./assets/Kellnermaske.jpg" alt="post-thumb">

          <hr class="divider my-4 colorchange" />
          <h4><a class="text-dark" href="blog-single.html">Corona Regeln</a></h4>
          <p class="text-muted mb-0">Zu ihrer eigenen Sicherheit, tragen alle unsere Mitarbeiter Schutzmasken, welche sie stündlich wechseln. Ausserdem bitten wir Sie, mit nicht mehr als 4 Personen in unsere Kaffees zu kommen.</p>
        </article>
      </div>
      <div class="col-lg-4 col-sm-6 mb-5">
        <article class="text-center">
          <img class="img-fluid mb-4" src="./assets/shop2.jpg" alt="post-thumb">

          <hr class="divider my-4 colorchange" />
          <h4><a class="text-dark" href="blog-single.html">St.Gallen Kaffee</a></h4>
          <p class="text-muted mb-0">In St.Gallen wurde unser 13 Kaffee eröffnet. Wir freuten uns alle über den grossen Ansturm und nochmehr über das tolle Feedback. </p>
        </article>
      </div>
    </div>
  </div>
  </div>
</section>
</div>
