import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bern-paradeplatz',
  templateUrl: './bern-paradeplatz.component.html',
  styleUrls: ['./bern-paradeplatz.component.scss']
})
export class BernParadeplatzComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
