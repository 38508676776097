
<header class="masthead">
  <div class="container h-100">
    <div class="row h-100 align-items-center justify-content-center text-center">
      <div class="col-lg-10 align-self-end">
        <h1 class="text-uppercase text-white font-weight-bold">Kontaktieren Sie uns</h1>
        <hr class="divider my-4 colorchange" />
      </div>
      <div class="col-lg-8 align-self-baseline">
        <p class="text-white-75 font-weight-light mb-5">Reichen Sie eine Bewerbung, eine Beschwerde oder einfach positives Feedback ein. </p>
      </div>
    </div>
  </div>
</header>

<div class="margin">
<div class="container">
<form id="contact-form" method="post" action="contact.php" role="form">

  <div class="messages"></div>

  <div class="controls">

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="form_name">Vorname</label>
          <input id="form_name" type="text" name="name" class="form-control" placeholder="Vorname" required="required" data-error="Firstname is required.">
          <div class="help-block with-errors"></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="form_lastname">Nachname</label>
          <input id="form_lastname" type="text" name="surname" class="form-control" placeholder="Nachname" required="required" data-error="Lastname is required.">
          <div class="help-block with-errors"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="form_email">Email</label>
          <input id="form_email" type="email" name="email" class="form-control" placeholder="Email" required="required" data-error="Valid email is required.">
          <div class="help-block with-errors"></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="form_need">Grund für Anfrage</label>
          <select id="form_need" name="need" class="form-control" required="required" data-error="Please specify your need.">
            <option value="Request quotation">Reservation</option>
            <option value="Request order status">Bewerbung</option>
            <option value="Request copy of an invoice">Beschwerde</option>
            <option value="Other">anderes</option>
          </select>
          <div class="help-block with-errors"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="form_message">Nachricht</label>
          <textarea id="form_message" name="message" class="form-control" placeholder="" rows="4" required="required" data-error="Please, leave us a message."></textarea>
          <div class="help-block with-errors"></div>
        </div>
      </div>
      <div class="col-md-12">
        <input type="submit" class="btn btn-success btn-send" value="Abschicken">
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
      </div>
    </div>
  </div>

</form>
</div>
</div>
