
<div class="margin">
  <div class="container">
    <h2 class="text-center mt-0">Unser Angebot</h2>
    <hr class="divider my-4 colorchange"/>
    <section class="section">
  <section class="content-section margin" id="portfolio">
  <div class="container">
    <div class="row no-gutters">
      <div class="col-lg-6" routerLink="/produkte">
        <a class="portfolio-item">
            <span class="caption">
              <span class="caption-content">
                <h3 class="h4 mb-2">Kaffee</h3>
              </span>
            </span>
          <img class="img-fluid" src="./assets/products-coffee.png" type="text/css" alt="">
        </a>
      </div>
      <div class="col-lg-6" routerLink="/produkte">
        <a class="portfolio-item">
            <span class="caption">
              <span class="caption-content">
                <h3  class="h4 mb-2">Heissegetränke</h3>
              </span>
            </span>
          <img class="img-fluid" src="./assets/products-hotdrinks.png" type="text/css" alt="">
        </a>
      </div>
      <div class="col-lg-6" routerLink="/produkte">
        <a class="portfolio-item">
            <span class="caption">
              <span class="caption-content">
                <h3  class="h4 mb-2">Backwaren</h3>
              </span>
            </span>
          <img class="img-fluid" src="./assets/products-backery.png" type="text/css" alt="">
        </a>
      </div>
      <div class="col-lg-6" routerLink="/produkte">
        <a class="portfolio-item">
            <span class="caption">
              <span class="caption-content">
                <h3  class="h4 mb-2">Snacks</h3>
              </span>
            </span>
          <img class="img-fluid" src="./assets/products-snacks.png" type="text/css" alt="">
        </a>
      </div>
    </div>
  </div>
</section>



