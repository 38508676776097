<div class="padding">
<section id="services">
  <div class="container">
    <h2 class="text-center mt-0">Unsere Werte</h2>
    <hr class="divider my-4 colorchange" />
    <div class="row">
      <div class="col-lg-3 col-md-6 text-center">
        <div class="mt-5">
          <i class="fas fa-4x fa-gem text-primary mb-4"></i>
          <h3 class="h4 mb-2">Nachhaltigkeit</h3>
          <p class="text-muted mb-0">Wir versuchen unseren biologischen Fussabdruck so klein wie möglich zu halten</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 text-center">
        <div class="mt-5">
          <i class="fas fa-4x fa-laptop-code text-primary mb-4"></i>
          <h3 class="h4 mb-2">Motivation</h3>
          <p class="text-muted mb-0">Unsere Mitarbeiter sind uns sehr wichtig und wir das spiegelt sich auf die Motivation wieder</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 text-center">
        <div class="mt-5">
          <i class="fas fa-4x fa-globe text-primary mb-4"></i>
          <h3 class="h4 mb-2">Neugierig</h3>
          <p class="text-muted mb-0">Wir entwickeln uns kontinuierlich weiter und ergreifen neue Chancen</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 text-center">
        <div class="mt-5">
          <i class="fas fa-4x fa-heart text-primary mb-4"></i>
          <h3 class="h4 mb-2">Vertrauenswürdig</h3>
          <p class="text-muted mb-0">Wir sind nah bei unseren Kunden und Mitarbeitenden und handeln verantwortungsvoll und zuverlässig</p>
        </div>
      </div>
    </div>
    <div class="margin-top">
    <img src="./assets/coffeeshop.png" class="img-fluid" alt="Responsive image">
    </div>
  </div>
</section>
</div>
