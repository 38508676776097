<nav class="navbar navbar-expand-lg navbar-light fixed-top py-3 shadow" id="mainNav">
  <div class="container">
    <img class="logo" routerLink="/" src="/assets/Native-Coffee-Logo.svg">
    <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav ml-auto my-2 my-lg-0">
        <li class="nav-item"><a class="nav-link js-scroll-trigger black" routerLink="">Home</a></li>
        <li class="nav-item"><a class="nav-link js-scroll-trigger black" routerLink="produkte">Angebote</a></li>
        <li class="nav-item"><a class="nav-link js-scroll-trigger black" routerLink="geschichte">Geschichte</a></li>
        <li class="nav-item dropdown"><a class="nav-link dropdown-toggle black" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Über uns
        </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" routerLink="kontakt">Kontakt</a>
            <a class="dropdown-item" routerLink="standorte/wil">Standorte</a>
          </div></li>
      </ul>
    </div>
  </div>
</nav>


