<!-- Site footer -->
<footer class="site-footer">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h6>Native Coffee</h6>
        <p class="text-justify">Native Coffee ist eine Reihe von Kaffees, welche in der ganzen Schweiz vertretten ist. Native Coffee setzt dabei stark auf ihren Ökologischen Fussabdruck und versucht es unseren Kunden weiter zu leiten.</p>
      </div>

      <div class="col-xs-6 col-md-3">
        <h6>Grüder</h6>
        <ul class="footer-links">
          <li><a>Marvin Frei</a></li>
          <li><a>Ueli Maurer</a></li>
          <li><a>Sepp Toni</a></li>
        </ul>
      </div>

      <div class="col-xs-6 col-md-3">
        <h6>Stichwörter</h6>
        <ul  feclass="footer-links">
          <li><a href="http://scanfcode.com/about/">Kaffee</a></li>
          <li><a href="http://scanfcode.com/contact/">Nachhaltigkeit</a></li>
          <li><a href="http://scanfcode.com/contribute-at-scanfcode/">Lecker</a></li>
          <li><a href="http://scanfcode.com/privacy-policy/">Tee</a></li>
          <li><a href="http://scanfcode.com/sitemap/">Gesund</a></li>
        </ul>
      </div>
    </div>
    <hr>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-sm-6 col-xs-12">
        <p class="copyright-text">Copyright &copy; 2020 All Rights Reserved by
          <a href="#">native-coffee</a>.
        </p>
      </div>
    </div>
  </div>
</footer>
