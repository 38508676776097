import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stgallebhsg',
  templateUrl: './stgallebhsg.component.html',
  styleUrls: ['./stgallebhsg.component.scss']
})
export class StgallebhsgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
